import { useState } from "react";
import styles from "./Home.module.css";
import "react-simple-toasts/dist/theme/dark.css";
import { AlreadyRegistered } from "../../components/AlreadyRegistered";
import { InitialForm, InitialTradespersonData } from "../../components/InitialForm";
import { RegistrationModal } from "../../components/RegistrationModal";
import { usePageViewEvent } from "../../hooks/usePageViewEvent";

const Home = () => {
  usePageViewEvent();

  const [initialTradespersonData, setInitialTradespersonData] =
    useState<InitialTradespersonData | null>(null);

  const handleNextStepClick = (initialData: InitialTradespersonData) => {
    setInitialTradespersonData(initialData);
  };

  const handleRegistrationModalClose = () => {
    setInitialTradespersonData(null);
  };

  return (
    <main className={styles.main}>
      <section className={styles.logoSection}>
        <img className={styles.logoImage} src="../static/images/logo.png" alt="Instapro logo" />
      </section>
      <section className={styles.formSection}>
        <div className={styles.initialFormContainer}>
          <h1>A new way to get more of the work you want.</h1>
          <p className={styles.subText}>
            Are you a Boston pro looking for a reliable way to access leads that are just right for
            you? Share your details below for updates about an exciting new service coming soon.
          </p>

          <InitialForm onNextStepClick={handleNextStepClick} />
        </div>
      </section>

      <section className={styles.promoImageSection}>
        <div className={styles.promoImageContainer}>
          <picture>
            <source media="(max-width: 400px)" srcSet="../static/images/main-image-400w.avif" />
            <source media="(max-width: 600px)" srcSet="../static/images/main-image-600w.avif" />
            <source media="(max-width: 800px)" srcSet="../static/images/main-image-800w.avif" />
            <source media="(max-width: 1024px)" srcSet="../static/images/main-image-1024w.avif" />
            <source media="(max-width: 1200px)" srcSet="../static/images/main-image-1200w.avif" />
            <source media="(min-width: 1200px)" srcSet="../static/images/main-image.jpg" />

            <img
              className={styles.promoImage}
              src="../static/images/main-image.jpg"
              alt="Two professionals at work"
            />
          </picture>

          <AlreadyRegistered className={styles.alreadyAppliedContainer} />
        </div>
      </section>

      <RegistrationModal
        isOpen={!!initialTradespersonData}
        initialTradespersonData={initialTradespersonData}
        onClose={handleRegistrationModalClose}
      />
    </main>
  );
};

export default Home;
