export const PROFESSIONS = [
  "Carpenter",
  "Electrician",
  "Floor fitter",
  "Gardener",
  "General Contractor",
  "Handyman",
  "Landscaper",
  "Painters",
  "Plasterers",
  "Plumber",
  "Pitched roofing",
  "Tiler",
  "Drywall contractors",
  "Air Conditioning Specialist",
  "Bricklaying",
  "Window/Door Fitter (Wooden)",
  "Sewage & Drain Specialist",
  "Flat roofing",
  "Central Heating Specialist",
  "Window/Door Fitter (uPVC & Metal)",
  "Stonemasonry",
];

export const PROFESSION_INPUT_PLACEHOLDER = "Select profession";
