import { ReactNode, HTMLAttributes } from "react";
import { ReactComponent as Loader } from "./loader.svg";
import styles from "./LoadingButton.module.css";

type Props = {
  isLoading?: boolean;
  disabled?: boolean;
  children: ReactNode;
} & HTMLAttributes<HTMLButtonElement>;

const LoadingButton = ({ isLoading, children, ...restProps }: Props) => {
  return (
    <button type="submit" className={styles.button} {...restProps}>
      <div className={styles.contentContainer}>
        {isLoading && (
          <span className={styles.loaderContainer}>
            <Loader className={styles.loader} />
          </span>
        )}
        {children}
      </div>
    </button>
  );
};

export default LoadingButton;
