import { SyntheticEvent, useEffect, useRef } from "react";
import styles from "./InitialForm.module.css";
import { LoadingButton } from "../LoadingButton";
import { PROFESSION_INPUT_PLACEHOLDER, PROFESSIONS } from "../../constants";

export type InitialTradespersonData = {
  profession: (typeof PROFESSIONS)[number];
  email: string;
  zipCode: string;
};
type Props = { onNextStepClick: (initialData: InitialTradespersonData) => void };

const InitialForm = ({ onNextStepClick }: Props) => {
  const professionInputRef = useRef<HTMLSelectElement | null>(null);

  useEffect(() => {
    professionInputRef.current?.setCustomValidity?.("Please choose a profession");
  }, []);

  const handleInitialFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());

    onNextStepClick(data as InitialTradespersonData);
  };

  const handleProfessionValueChange = () => {
    professionInputRef.current?.setCustomValidity?.("");
  };

  return (
    <form className={styles.container} onSubmit={handleInitialFormSubmit} method="post">
      <label>
        <b>Profession</b>
        <select
          ref={professionInputRef}
          name="profession"
          defaultValue={PROFESSION_INPUT_PLACEHOLDER}
          onChange={handleProfessionValueChange}
          required
        >
          <option
            value={PROFESSION_INPUT_PLACEHOLDER}
            disabled
            hidden
            key={PROFESSION_INPUT_PLACEHOLDER}
          >
            {PROFESSION_INPUT_PLACEHOLDER}
          </option>
          {PROFESSIONS.map((professionName) => (
            <option value={professionName} key={professionName}>
              {professionName}
            </option>
          ))}
        </select>
      </label>

      <label>
        <b>Zip code</b>
        <input
          type="text"
          name="zipCode"
          placeholder="12345"
          required
          minLength={5}
          maxLength={5}
          pattern="\d{5}"
          title="Zip code should consist from five digits."
        />
      </label>

      <label>
        <b>Email</b>
        <input type="email" name="email" placeholder="john@gmail.com" required />
      </label>

      <LoadingButton id="next">Next</LoadingButton>
    </form>
  );
};

export default InitialForm;
