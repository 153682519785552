import styles from "./RegistrationModal.module.css";
import { useState } from "react";
import { LoadingButton } from "../LoadingButton";
import toast, { toastConfig } from "react-simple-toasts";
import { createPortal } from "react-dom";
import { PROFESSIONS } from "../../constants";
import { InitialTradespersonData } from "../InitialForm";
import { useNavigate } from "react-router-dom";

toastConfig({ theme: "dark" });

type ResponseData = {
  notSupportedArea?: boolean;
};

const TOAST_DURATION = 5000;
const GENERAL_ERROR_MESSAGE = "An error has occurred! Please try again later!";

const showToast = (message: string) => {
  toast(message, { duration: TOAST_DURATION, clickClosable: true });
};

type Props = {
  initialTradespersonData: InitialTradespersonData | null;
  isOpen: boolean;
  onClose: () => void;
};

const RegistrationModal = ({ isOpen, onClose, initialTradespersonData }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleOnSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      const response = await fetch("/api/register", {
        method: "POST",
        body: new FormData(event.target as HTMLFormElement),
      });

      if (!response.ok) {
        throw Error(GENERAL_ERROR_MESSAGE);
      }

      const responseData = await response.json<ResponseData>();
      if (responseData.notSupportedArea) {
        navigate("/service-pro/confirmation?notSupportedArea=true");
      } else {
        navigate("/service-pro/confirmation");
      }
    } catch {
      showToast(GENERAL_ERROR_MESSAGE);
    } finally {
      setIsSubmitting(false);
    }
  };

  return isOpen
    ? createPortal(
        <>
          <div className={styles.overlay} onClick={onClose} />
          <div className={styles.container}>
            <div className={styles.closeButtonContainer}>
              <button className={styles.closeButton} onClick={onClose}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.47008 2.49003L2.98005 2L2 2.98005L2.49003 3.47008L11.0199 12L2.49003 20.5299L2 21.0199L2.98005 22L3.47008 21.51L12 12.9801L20.5299 21.51L21.0199 22L22 21.0199L21.51 20.5299L12.9801 12L21.51 3.47008L22 2.98005L21.0199 2L20.5299 2.49003L12 11.0199L3.47008 2.49003Z"
                    fill="#262627"
                  />
                </svg>
              </button>
            </div>

            <form
              className={styles.registrationForm}
              onSubmit={handleOnSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <div className={styles.formRow}>
                <label>
                  <b>Profession</b>
                  <select
                    name="profession"
                    defaultValue={initialTradespersonData?.profession || "Select profession"}
                  >
                    <option value="Select profession" disabled hidden key="Select profession">
                      Select profession
                    </option>
                    {PROFESSIONS.map((professionName) => (
                      <option value={professionName} key={professionName}>
                        {professionName}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  <b>Zip code</b>
                  <input
                    type="text"
                    name="zipCode"
                    defaultValue={initialTradespersonData?.zipCode}
                    placeholder="12345"
                    required
                    minLength={5}
                    maxLength={5}
                    pattern="\d{5}"
                    title="Zip code should consist from five digits."
                  />
                </label>
              </div>

              <div className={styles.formRow}>
                <label>
                  <b>Email</b>
                  <input
                    type="email"
                    name="email"
                    defaultValue={initialTradespersonData?.email}
                    placeholder="john@gmail.com"
                    required
                  />
                </label>

                <label>
                  <b>Company name</b>

                  <input
                    type="text"
                    name="companyName"
                    placeholder="Painting inc."
                    required
                    minLength={2}
                  />
                </label>
              </div>

              <div className={styles.formRow}>
                <label>
                  <b>First name</b>

                  <input type="text" name="firstName" placeholder="John" required minLength={2} />
                </label>

                <label>
                  <b>Last name</b>

                  <input
                    type="text"
                    name="lastName"
                    placeholder="Matthews"
                    required
                    minLength={2}
                  />
                </label>
              </div>

              <div className={styles.formRow}>
                <label>
                  <b>Phone number</b>

                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="+1 1234567890"
                    required
                    minLength={12}
                    maxLength={13}
                    pattern="^\+1\s?\d{10}$"
                    title="Use one of this formats: +11234567890 or +1 1234567890"
                  />
                </label>
              </div>

              <div className={styles.submitButtonContainer}>
                <LoadingButton isLoading={isSubmitting} disabled={isSubmitting} id="register">
                  Claim your spot
                </LoadingButton>
              </div>

              <p>
                By clicking on "Claim your spot" you accept the General Conditions of Use{" "}
                <a
                  href="https://www.instapro.com/terms-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  General Conditions of Use
                </a>{" "}
                of Instapro.com. To learn more about how we process your data, we invite you to
                consult our{" "}
                <a href="https://www.instapro.com/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy.
                </a>
              </p>
            </form>
          </div>
        </>,
        document.body
      )
    : null;
};

export default RegistrationModal;
