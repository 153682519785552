import styles from "./Avatars.module.css";

const AVATARS = [
  "../static/images/avatar1.png",
  "../static/images/avatar2.png",
  "../static/images/avatar3.png",
];

const Avatars = () => {
  return (
    <div className={styles.container}>
      {AVATARS.map((url, index) => (
        <img
          className={styles.image}
          style={{ zIndex: AVATARS.length - index }}
          src={url}
          key={url}
          alt="avatar of tradesperson"
        />
      ))}
    </div>
  );
};

export default Avatars;
