import { useEffect } from "react";

const usePageViewEvent = () => {
  useEffect(() => {
    // @ts-expect-error dataLayer is integrated by GTM
    window?.dataLayer?.push?.({
      event: "VirtualPageView",
      virtualPagePath: window?.location?.href,
      referrer: document?.referrer,
    });
  }, []);
};

export { usePageViewEvent };
