import styles from "./AlreadyRegistered.module.css"
import { Avatars } from "../Avatars";
import { RoundedPanel } from "../RoundedPanel";
import { HTMLAttributes } from "react";

const AlreadyRegistered = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <RoundedPanel className={className}>
      <Avatars />
      <div className={styles.textContainer}>
        <p className={styles.mainText}>245+ people</p>
        <p className={styles.subText}>already registered</p>
      </div>
    </RoundedPanel>
  )
}

export default AlreadyRegistered;
