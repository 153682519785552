import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { ConfirmationPage } from "./pages/ConfirmationPage";

const App = () => {
  return (
    <Routes>
      <Route path="/service-pro">
        <Route path="registration" element={<Home />} />
        <Route path="confirmation" element={<ConfirmationPage />} />

        <Route index element={<Navigate to="registration" />} />
        <Route path="*" element={<Navigate to="registration" />} />
      </Route>

      <Route path="/">
        <Route index element={<Navigate to="service-pro/registration" />} />
        <Route path="*" element={<Navigate to="service-pro/registration" />} />
      </Route>
    </Routes>
  );
};

export default App;
