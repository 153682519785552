import { HTMLAttributes } from "react";
import styles from "./RoundedPanel.module.css"


const RoundedPanel = ({ className, children }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`${styles.container} ${className}`}>
      {children} 
    </div>
  )
}

export default RoundedPanel;
